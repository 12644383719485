import styled, { css } from 'styled-components';
// import ReactJWPlayer from 'react-jw-player';

export const VideoPlayer = styled.video`
  width: 100%;
  height: 100vh;
  object-fit: fill;

  > div {
    width: 100% !important;
    // height: 100% !important;
  }

  .btn-play {
    z-index: 3;
    position: absolute;
    //border-radius: 16px;
    border: 0;
    background-color: #00000085;
    transition: all 0.3s ease-in-out;
    top: 0;
    :hover {
      transform: scale(1.5);
    }
  }

  .opacity-0 {
    opacity: 0;
    display: none;
  }

  .vjs-poster img {
    object-fit: cover !important;
  }

  .vjs-big-play-button {
    display: none !important;
    transition: all 0.3s ease-in-out !important;
    background-color: transparent !important;
    border: none !important;
  }

  :hover {
    .vjs-big-play-button {
      font-size: 3em;
      line-height: 1.5em;
      height: 1.63332em;
      width: 3em;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 0;
      margin-top: -0.81666em;
      margin-left: -1.5em;
      cursor: pointer;
      opacity: 1;

      border-radius: 0.3em;

      transform: scale(1.5);
    }
  }
`;
